import React, { useState, useEffect } from 'react';
import {
    map as _map,
    findIndex as _findIndex,
} from 'lodash';
import QuestionnaireItem from './questionnaire-item';
import Roadmap from './roadmap';
import Steps from './steps';
import sanitizeUrl from '@utils/sanitize-url';
import { Hero } from '@molecules/hero';
import { Button } from '@atoms/button';
import Section from '@atoms/section';
import StepsContext from '@context/steps';
import smoothscroll from 'smoothscroll-polyfill';

import './questionnaire.scss';
import {useTranslation} from "gatsby-plugin-react-i18next";

if(typeof window !== 'undefined') {
    smoothscroll.polyfill();
}

const heroContent = [
    {
        title: 'questionnaire:heroTitle',
        description: 'questionnaire:heroDescription',
        info: 'questionnaire:heroInfo',
        caveat: 'questionnaire:heroCaveat',
    },
    {
        title: 'questionnaire:roadmapHeroTitle',
        description: 'questionnaire:roadmapHeroDescription',
        info: 'questionnaire:roadmapHeroInfo',
        caveat: 'questionnaire:roadmapHeroCaveat',
    }
];

const Questionnaire = () => {
    const { t } = useTranslation();
    const { steps, setSteps } = React.useContext(StepsContext);
    const [currentStepIndex, setCurrentStep] = useState(0);
    const titles = _map(steps, step => step.title);

    const isAnStep = currentStepIndex < 3;
    const currentHeroContent = isAnStep ? heroContent[0] : heroContent[1];
    const heroClassName = isAnStep ? '' : 'small';
    const { title: heroTitle = '', description: heroDescription = '', info: heroInfo = '', caveat: heroCaveat = '' } = currentHeroContent || {};
    /* Insert the Roadmap item */
    titles && titles.push(t('Your roadmap'));

    const stepsRef = React.createRef();
    const nextStepMessage = currentStepIndex !== 2 ? `${t(`Continue with Part`)} ${currentStepIndex + 2}` : t(`Create my roadmap`);
    const nextStepTo = sanitizeUrl(t(titles[currentStepIndex + 1]));

    const moveScrollToCurrentStepPosition = (newStep) => {
        if (stepsRef && stepsRef.current) {
            const isWindow = typeof window !== 'undefined';
            if (isWindow) {
                const element = stepsRef.current;
                setTimeout(() => {
                    const rect = element.getBoundingClientRect();
                    const topPosition = rect.top + window.window.scrollY;
                    window.scrollTo({
                        top: newStep === 3 ? 0 : topPosition, // Move to top only in step 3 (Your roadmap)
                        behavior: 'smooth',
                    });
                }, 1);
            }
        }
    };
    const assignCurrentStep = (stepIndex, moveToCurrentStep = true) => {
        if (moveToCurrentStep) {
            moveScrollToCurrentStepPosition(stepIndex);
        }
        setCurrentStep(stepIndex);
    };
    const nextStepButton = isAnStep ? (
        <div className="text-right">
            <Button to={`/questionnaire/#${nextStepTo}`} onClick={() => assignCurrentStep(currentStepIndex + 1)}>
                { nextStepMessage }
            </Button>
        </div>
    ) : null;

    const currentStep = steps && steps.length && steps[currentStepIndex];
    const questions = currentStep && currentStep.questions;

    const onQuestionnaireItemClick = (question) => {
        const index = _findIndex(questions, questionItem => questionItem.question === question);

        if (index >= 0) {
            const newSteps = [...steps];
            const currentStep = newSteps && newSteps.length && newSteps[currentStepIndex];
            const newQuestions = currentStep && currentStep.questions;
            const currentQuestion = newQuestions[index];

            currentQuestion.active = !currentQuestion.active;
            setSteps(newSteps);
        }
    };

    useEffect(() => {
        const hash = window.location.href.indexOf('#')
        const originUrl = `${window.location.origin}${window.location.pathname}#`;
        const currentPath = window.location.href.replace(originUrl, '');

        if(!currentPath || hash < 0) {
            return;
        }

        const index = _findIndex(titles, title => sanitizeUrl(t(title)) === currentPath);

        if (index !== currentStepIndex) {
            setCurrentStep(index);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titles, currentStepIndex]);


    return (
        <React.Fragment>
            <Hero
                title={t(heroTitle)}
                description={t(heroDescription)}
                info={t(heroInfo)}
                infoIcon={false}
                caveat={t(heroCaveat)}
                caveatIcon
                className={heroClassName}
            />
            <section className="questionnaire">
                <div className="questionnaire__steps" ref={stepsRef}>
                    <Steps items={titles} onClick={(index) => assignCurrentStep(index)} currentStep={currentStepIndex} />
                </div>
                {!currentStep ? (<Roadmap steps={steps} />) : null}
                {
                    currentStep ? (
                        <React.Fragment>
                            <div className="questionnaire__questions-bg">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <article className="questionnaire__questions">
                                                <ul className="list-unstyled">
                                                    {
                                                        _map(questions, (question, index) => (
                                                                <li key={index}>
                                                                    <QuestionnaireItem
                                                                        chapter={question?.content?.chapter}
                                                                        question={question.question}
                                                                        active={question.active}
                                                                        onClick={onQuestionnaireItemClick}
                                                                    >
                                                                    </QuestionnaireItem>
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                </ul>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { nextStepButton && <Section className="mt-4 mb-6">{nextStepButton}</Section> }
                        </React.Fragment>
                    ) : null
                }
            </section>
        </React.Fragment>
    );
};

Questionnaire.propTypes = {};

Questionnaire.propTypesDefault = {};

export default Questionnaire;
