import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import {
    map as _map,
    forEach as _forEach,
    filter as _filter,
    find as _find,
} from 'lodash';
import ChapterItem from './chapter-item';
import chapters from '@config/chapters';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Button, BUTTON_TYPE_GRADIENT_LINK, BUTTON_TYPE_RIGHT_ARROW } from '@atoms/button'
import sanitizeUrl from '@utils/sanitize-url';
import { Alert } from '@molecules/alert';
import { feedbackLink } from '../footer';

import './roadmap.scss';

const COMPONENT_CLASS = 'roadmap';
const CHAPTER_CLASS = 'chapter';
const EVEN_CLASS = 'even';
const ODD_CLASS = 'odd';
const MODIFIER_SEPARATOR = '--';
const QUICKLINK_CLASS = 'quicklinks';

const QUERY_GET_ALL_ICONS = graphql`
query{
    allFile(filter: {extension: {eq: "svg"}, relativeDirectory: {eq: "icons"}}) {
        edges {
            node {
                name
                publicURL
            }
        }
    }
}`;

const Roadmap = ({ steps }) => {
    const { t, i18n } = useTranslation();
    const queryData = useStaticQuery(QUERY_GET_ALL_ICONS);
    const icons = _map(queryData?.allFile?.edges, edge => {
        return edge?.node || {};
    });
    const feedbackUrl = feedbackLink[i18n.language];

    /* Collect user questions anc chapters */
    const chaptersToShow = {};
    let userQuestions = [];
    _forEach(steps, step => {
        const filteredUserQuestions = _filter(step.questions, question => question.active);
        userQuestions = [...userQuestions, ...filteredUserQuestions];

        _forEach(userQuestions, question => {
            const chapter = question?.content?.chapter;

            if(!chaptersToShow[chapter]) {
                const chapterData = chapters && chapters[chapter - 1];
                const currentIcon = _find(icons, icon => icon.name === chapterData.icon);
                chaptersToShow[chapter] = {...chapterData, counter: 0, icon: {icon: chapterData.icon, ...currentIcon}};
            }

            chaptersToShow[chapter].counter += 1;
        });
    });

    let counter = 1;
    const howManyChapters = Object.keys(chaptersToShow).length;
    const oddEvenModifier = howManyChapters % 2 === 0? EVEN_CLASS : ODD_CLASS;
    const roadmapClasses = classNames(COMPONENT_CLASS, `${COMPONENT_CLASS}--${howManyChapters}`);
    const quicklinksClasses = classNames('container', QUICKLINK_CLASS, QUICKLINK_CLASS + MODIFIER_SEPARATOR + oddEvenModifier);

    return (
        <section className={roadmapClasses}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <Alert>
                            <Trans>You can visit the entire chapters or jump to the specific sections that your questionnaire identified using the quick links further below.</Trans>
                        </Alert>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {
                        _map(chaptersToShow, (chapter) => {
                            const isEven = counter % 2 === 0;
                            const position = isEven ? `${CHAPTER_CLASS}${MODIFIER_SEPARATOR}${EVEN_CLASS}` : `${CHAPTER_CLASS}${MODIFIER_SEPARATOR}${ODD_CLASS}`;
                            const line = Math.floor(counter / 2)

                            const row = `${CHAPTER_CLASS}${MODIFIER_SEPARATOR}line-${line}`;
                            const isLast = howManyChapters === counter ? `${CHAPTER_CLASS}${MODIFIER_SEPARATOR}last` : '';

                            const itemClasses = classNames(`col-md-6 ${CHAPTER_CLASS}`, `${CHAPTER_CLASS}-${counter++}`, row, position, isLast);

                            return (
                                <div key={chapter.id} className={itemClasses}>
                                    <ChapterItem data={chapter}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <section className={quicklinksClasses}>
                <div className="quicklinks__arrow"/>
                <div className="row">
                    <div className="col-12">
                        <h2 className="quicklinks__title text-left">
                            <span>
                                <Trans>Quick links to your topics</Trans>
                            </span>
                        </h2>
                    </div>
                    <div className="col-12">
                        <ul className="list-unstyled quicklinks__items">
                            {
                                _map(userQuestions, (questionItem, key) => {
                                    const { question = '', content: { anchor = '', chapter = 0 } = {} } = questionItem;
                                    const translatedAnchor = t(anchor);
                                    const anchorUrl = anchor ? `#${sanitizeUrl(translatedAnchor)}` : '';

                                    const linkSuffix = i18n.language === 'en' ? '/chapters/chapter' : '/chapitres/chapitre';
                                    const link = `${linkSuffix}-${chapter}${anchorUrl}`;

                                    return (
                                        <li className="quicklinks__item" key={key}>
                                            <Button to={link} type={[BUTTON_TYPE_GRADIENT_LINK, BUTTON_TYPE_RIGHT_ARROW]}>
                                                {question}
                                            </Button>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </section>
            <section className="container print-pdf">
                <div className="row align-items-center">
                    <div className="col-md-8 col-xl-6 text-center text-md-left mt-4 mt-md-0">
                        <h2 className={'font-weight-bold'}>{t('Print your roadmap or save as a PDF')}</h2>
                    </div>
                    <div className="col-md-4 col-xl-6 text-center text-md-right mt-4 mt-md-0">
                        <Button to={`/my-roadmap-pdf`} target={'_blank'}>Print your roadmap</Button>
                    </div>
                </div>
                <div className="row align-items-center mt-3 mt-md-6">
                    <div className="col-md-8 col-xl-6 text-center text-md-left mt-4 mt-md-0">
                        <h2 className={'font-weight-bold'}>{t('We want to know what you think!')}</h2>
                    </div>
                    <div className="col-md-4 col-xl-6 text-center text-md-right mt-4 mt-md-0">
                        <Button to={feedbackUrl} target={'_blank'}>Submit feedback</Button>
                    </div>
                </div>
            </section>
        </section>
    );

};

Roadmap.propTypes = {
    steps: PropTypes.array,
};

Roadmap.propTypesDefault = {
    steps: [],
};

export default Roadmap;
