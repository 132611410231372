import React from 'react';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SquareIcon from '@bootstrap-icons/square.svg';
import SquareIconActive from '@bootstrap-icons/check2-square.svg';
import './questionnaire-item.scss';

const COMPONENT_CLASS = 'questionnaire__item';

const QuestionnaireItem = ({ question, active, onClick, chapter }) => {
    const classes = classNames(COMPONENT_CLASS, 'stretched-link', {
        [`${COMPONENT_CLASS }--active`]: active
    });
    const clickFunction = (e, question) => {
        e.preventDefault();
        if(typeof onClick === 'function') {
            onClick(question);
        }
    };


    const checkbox = (<img className="questionnaire__item_checkbox-img" src={active ? SquareIconActive : SquareIcon} alt="" width="26" height="26" />);

    return (
        <Link to="#" onClick={(e) => clickFunction(e, question) } className={ classes }>
            <span className="questionnaire__item_checkbox">
                { checkbox }
            </span>
            <span className="questionnaire__item_text">
                <Trans>{ question }</Trans>
            </span>
        </Link>
    );
};

QuestionnaireItem.propTypes = {
    question: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    chapter: PropTypes.number,
};

QuestionnaireItem.defaultProps = {
    question: '',
    active: false,
    onClick: () => {},
};

export default QuestionnaireItem;
