import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from '@components/layout';
import SEO from '@components/seo';
import Questionnaire from "@components/roadmap/questionnaire";

import './questionaire.scss';

const QuestionnairePage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t('questionnaire:title')} />
      <Questionnaire />
    </Layout>
  )
};

export default QuestionnairePage
